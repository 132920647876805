import React from "react";
const phone = "+977-9818694634";
function Cta() {
  return (
    <div className="font-poppins">
      <section className="py-16 bg-white overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="mb-5 text-3xl md:text-5xl font-bold font-heading text-center tracking-px-n leading-tight">
              If you have any queries regarding our services, please contact us.
            </h2>
            <p className="mb-7 text-lg text-gray-600 font-medium">
              We will try our best to assist you.
            </p>
            <div className="mb-11 md:inline-block">
              <a
                 href={`tel:${phone}`}
                className="uppercase tracking-wider py-4 text-xl px-6 w-full text-white font-semibold border border-black rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-black hover:bg-black transition ease-in-out duration-200"
                type="button"
              >
              {phone}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Cta;
