import React from "react";
import bannerImage from "../assets/banner.png";

// ------------------------------- Component Contents -------------------------------
const heading = "We are Chhorten Enterprises";
const subHeading = "Company for Import & Export";
const phone = "+977-9818694634";
// ------------------------------- Component Contents Ends-------------------------------
function Hero() {
  return (
    <section
      id="hero"
      className="px-3 md:px-0"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <div className="font-poppins relative pt-[20px] pb-[50px] lg:pt-[100px]">
        <div className="container mx-auto">
          <div className="flex flex-col-reverse md:items-center md:flex-row">
            <div className="w-full px-4 text-center">
              <div className="hero-content pt-36 md:pt-40">
                <h1 className="text-dark mb-3 text-3xl md:text-4xl font-semibold leading-snug sm:text-[62px] lg:text-7xl">
                  {heading}
                </h1>
                <p className="text-body-color text-md md:text-4xl mb-8 text-center">
                  {subHeading}
                </p>
                <ul className="flex flex-wrap items-center justify-center">
                  <li>
                    <a
                      href={`tel:${phone}`}
                      className="bg-lblue inline-flex items-center justify-center rounded-lg py-4 px-6 text-center text-xl text-black hover:bg-opacity-90 sm:px-10 lg:px-8 xl:px-10"
                    >
                      {phone}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
