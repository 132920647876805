import React from "react";
import { FcTodoList } from "react-icons/fc";
import { BiTimeFive } from "react-icons/bi";
import { BsLightbulbFill } from "react-icons/bs";
import { BsHourglassSplit } from "react-icons/bs";

function Services() {
  const elements = [
    {
      id: 1,
      name: "Fast and Accurate",
      icon: <BiTimeFive className="text-4xl text-dblue" />,
      content:
        "Customers who are interested in importing or exporting goods will be able to quickly and efficiently find the information they need.",
    },
    {
      id: 2,
      name: "Strict Compliance ",
      icon: <FcTodoList className="text-4xl text-dblue" />,
      content:
        "Importing and exporting goods is subject to numerous regulations and compliance requirements. We emphasize our commitment to complying with these regulations.",
    },
    {
      id: 3,
      name: "Real Time Tracking",
      icon: <BsHourglassSplit className="text-4xl text-dblue" />,
      content:
        "Customers will be able to track their shipments in real-time, from the moment they leave your facility to the moment they arrive at their destination.",
    },
    {
      id: 4,
      name: "Innovation and Digitalization",
      icon: <BsLightbulbFill className="text-4xl text-dblue" />,
      content:
        "In today's digital age, customers businesses will be innovative and to use technology to make their lives easier.",
    },
  ];
  return (
    <section id="services" className="font-poppins py-4 md:py-16 mb-6 px-3 md:px-0">
      <div className="container mx-auto">
       <div className="flex items-center justify-center">
       <h1 className="mb-4 text-3xl font-semibold text-dblue uppercase tracking-tight leading-none md:text-4xl py-2 md:py-8">
          Discover Our Services
          <p className="ml-12 max-w-xs text-center h-2 bg-dblue"></p>
        </h1>
       </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 md:gap-8 space-y-6 md:space-y-0">
          {elements.map(function (element) {
            const { id, name,icon, content } = element;
            return (
              <div key={id} className="flex flex-col space-y-4 justify-center p-4 rounded-xl border-2 hover:border-nblue">
                <p className="text-center">{icon}</p>
                <p className="text-xl font-semibold capitalize">{name}</p>
                <p>
                  {content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;
